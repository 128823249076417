import React from 'react';
import Seo from '../components/Seo/Seo'
import {Layout} from '../components/Layout';

export default function Polityka() {
  return <Layout>
    <Seo title="Polityka prywatności" />
    <div className="max-w-7xl h-full mx-auto px-6 mb-10 md:px-0 md:py-28 flex flex-col justify-between text-justify">
      <h1 className="text-5xl text-center">Polityka prywatności</h1>
      <h3 className="text-3xl my-4">1. Informacje ogólne</h3>
      <span className="text-lg ml-10">
        <ol className="list-decimal indent-2">
          <li>Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: silent-code.com</li>
          <li>Operatorem serwisu oraz Administratorem danych osobowych jest: Silent-Code z siedzibą w Gnieźnie os.Kazimierza Wielkiego 1/10</li>
          <li>Adres kontaktowy poczty elektronicznej operatora: kontakt@silent-code.com</li>
          <li>Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.</li>
          <li>
            <p>Serwis wykorzystuje dane osobowe w następujących celach:</p>
            <ul className="list-disc ml-12">
              <li>Obsługa zapytań przez formularz</li>
              <li>Realizacja zamówionych usług</li>
              <li>Prezentacja oferty lub informacji</li>
            </ul>
          </li>
          <li>
            <p>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:</p>
            <ul className="list-disc ml-12">
              <li>Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.</li>
              <li>Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).</li>
            </ul>
          </li>
        </ol>
      </span>
      <h3 className="text-3xl my-4">2. Wybrane metody ochrony danych stosowane przez Operatora</h3>
      <span className="text-lg ml-10">
        <ol className="list-decimal indent-2">
          <li>Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.</li>
          <li>Operator okresowo zmienia swoje hasła administracyjne.</li>
          <li>Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania, wykorzystywanego przez Operatora do przetwarzania danych osobowych, co w szczególności oznacza regularne aktualizacje komponentów programistycznych.</li>
        </ol>
      </span>
      <h3 className="text-3xl my-4">3. Hosting</h3>
      <span className="text-lg ml-10">
        <ol className="list-decimal indent-2">
          <li>Serwis jest hostowany (technicznie utrzymywany) na serwerach operatora: nazwa.pl</li>
        </ol>
      </span>
      <h3 className="text-3xl my-4">4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych</h3>
      <span className="text-lg ml-10">
        <ol className="list-decimal indent-2">
          <li>
            W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:
            <ul className="list-disc ml-12">
              <li>
              upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu realizacji celu działania strony
              </li>
            </ul>
          </li>
          <li>
            Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż jest to konieczne do wykonania związanych z nimi czynności określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej niż przez 3 lata.
          </li>
          <li>
            Przysługuje Ci prawo żądania od Administratora:
            <ul className="list-disc ml-12">
              <li>dostępu do danych osobowych Ciebie dotyczących,</li>
              <li>ich sprostowania,</li>
              <li>usunięcia,</li>
              <li>ograniczenia przetwarzania,</li>
              <li>oraz przenoszenia danych.</li>
            </ul>
          </li>
          <li>
            Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interesów realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obrony roszczeń.
          </li>
          <li>
            Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
          </li>
          <li>
            Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.
          </li>
          <li>
            W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.
          </li>
          <li>
            Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii Europejskiej.
          </li>
        </ol>
      </span>
      <h3 className="text-3xl my-4">5. Informacje w formularzach</h3>
      <span className="text-lg ml-10">
        <ol className="list-decimal indent-2">
          <li>Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.</li>
          <li>Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).</li>
          <li>Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony zawierającej formularz.</li>
          <li>Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do czego on służy.</li>
        </ol>
      </span>
      <h3 className="text-3xl my-4">6. Logi Administratora</h3>
      <span className="text-lg ml-10">
        <ol className="list-decimal indent-2">
          <li>Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.</li>
        </ol>
      </span>
      <h3 className="text-3xl my-4">7. Istotne techniki marketingowe</h3>
      <span className="text-lg ml-10">
        <ol className="list-decimal indent-2">
          <li>Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google Analytics (Google Inc. z siedzibą w USA). Operator nie przekazuje do operatora tej usługi danych osobowych, a jedynie zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia: https://www.google.com/ads/preferences/</li>
        </ol>
      </span>
      <h3 className="text-3xl my-4">8. Informacja o plikach cookies</h3>
      <span className="text-lg ml-10">
        <ol className="list-decimal indent-2">
          <li>Serwis korzysta z plików cookies</li>
          <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
          <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.</li>
          <li>Pliki cookies wykorzystywane są w następujących celach:
            <ol className="list-decimal">
              <li>utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</li>
              <li>realizacji celów określonych powyżej w części "Istotne techniki marketingowe";</li>
            </ol>
          </li>
          <li>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</li>
          <li>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</li>
          <li>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</li>
          <li>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą być również przez współpracujące z operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).</li>
        </ol>
      </span>
      <h3 className="text-3xl my-4">9. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?</h3>
      <span className="text-lg ml-10">
        <ol className="list-decimal indent-2">
          <li>Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www</li>
          <li>
            W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę internetową, której używasz i postępuj zgodnie z instrukcjami:
            <ul className="list-disc ml-12">
              <li>Edge</li>
              <li>Chrome</li>
              <li>Safari</li>
              <li>Firefox</li>
              <li>Opera</li>
            </ul>
            Urządzenia mobilne:
            <ul className="list-disc ml-12">
              <li>Android</li>
              <li>Safari(iOS)</li>
            </ul>
          </li>
        </ol>
      </span>
    </div>
  </Layout>
}